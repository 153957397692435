<template>
  <div 
    v-if="overview" 
    class="box"
  >
    <div class="columns is-mobile is-multiline">
      <div class="column is-12">
        <p
          v-if="!valuedToday"
          class="field"
        >
          <span class="tag is-rounded has-text-weight-bold is-primary">
            {{ isFuturePast }} value for {{ date }}
          </span>
        </p>
        <div v-if="permissions.cazanaRetailValue">
          <p
            class="title has-text-info is-5 is-size-6-mobile has-text-weight-semibold"
          >
            Market value
          </p>
          <p
            class="subtitle is-2 is-size-3-mobile has-text-weight-bold has-text-info"
          >
            {{ pounds(overview.retail) }}
          </p>
        </div>
      </div>
      <div
        class="column is-12"
        v-if="(permissions.depreciation && onTheRoadPrice) || overview.remarket"
      >
        <div class="columns is-mobile is-multiline">
          <div class="column is-12 is-v-paddingless">
            <hr class="hr is-marginless">
          </div>
          <div class="column">
            <p
              v-if="overview.remarket"
              class="field is-size-6 has-text-weight-semibold has-text-primary"
            >
              Trade value
            </p>
            <p
              v-if="permissions.depreciation && onTheRoadPrice"
              class="field is-size-6 has-text-grey-dark"
            >
              Original cost new
            </p>
            <p
              v-if="permissions.depreciation && onTheRoadPrice && difference"
              class="field is-size-6 has-text-grey"
            >
              Depreciation
            </p>
          </div>
          <div class="column is-6 has-text-right">
            <p
              v-if="overview.remarket"
              class="field has-text-weight-semibold has-text-primary is-size-6"
            >
              {{ pounds(overview.remarket) }}
            </p>
            <p
              v-if="permissions.depreciation && onTheRoadPrice"
              class="field is-size-6 has-text-grey-dark"
            >
              {{ pounds(onTheRoadPrice) }}
            </p>
            <p
              v-if="permissions.depreciation && onTheRoadPrice && difference"
              class="field is-size-6 has-text-grey"
            >
              {{ pounds(difference) }}
            </p>
            <p
              v-if="excVat"
              :class="{ help: permissions.depreciation }"
              class="is-size-7 has-text-grey"
            >
              This vehicle's market valuation does not include VAT. The eligibility for VAT qualification depends on specific criteria, which can vary based on factors such as usage and registration.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <spinner 
    class="section" 
    v-else 
  />
</template>

<script>
import { formatMoney } from 'accounting'
import { mapGetters } from 'vuex'
import { formatDate, sameDay, isFutureDate } from '@/utils/date.utils.ts'
export default {
  name: 'CompanionValuationsClaims',
  components: {
    spinner: () => import('core/components/Spinner')
  },
  computed: {
    ...mapGetters('companion', ['overview', 'type']),
    ...mapGetters('auth', ['permissions']),
    date() {
      return formatDate(this.overview.date)
    },
    valuedToday() {
      return sameDay(this.overview.date)
    },
    onTheRoadPrice() {
      return this.overview?.ocn?.onTheRoadPrice
    },
    difference() {
      return this.overview?.ocn?.difference
    },
    excVat() {
      return this.type === 'LCV' || this.type === 'HCV'
    },
    isFuturePast() {
      return isFutureDate(this.overview.date) ? 'Future' : 'Historical'
    }
  },
  methods: {
    pounds(value) {
      return formatMoney(value, '£', 0)
    }
  }
}
</script>
